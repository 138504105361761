<template lang="pug">
  ProductForm(:data="data")
  //- pre {{ data }}
</template>

<script>
import ProductForm from './ProductForm'

export default {
  name: 'ProductAdd',
  components: {
    ProductForm
  },
  data () {
    return {
      data: {
        id: undefined,
        menu_order: 0,
        categories: [],
        cards: [
          {
            item_no: '',
            size: '',
            caliber: '',
            capacity: ''
          }
        ]
      }
    }
  },
  methods: {},
  //- beforeRouteLeave (to, from, next) {
  //-   const component = this.$refs['form'];
  //-   const { detail, detail_init } = component;
  //-   if (JSON.stringify(detail) === JSON.stringify(detail_init)) return next();
    
  //-   this.$Modal.confirm({
  //-     title: `提示`,
  //-     content: `内容已修改，是否保存`,
  //-     closable: true,
  //-     onOk: () => component.handleSubmit('form'),
  //-     onCancel: () => next(),
  //-   });
  //- }
  created() {
    //- setTimeout(() => {
    //-   this.$set(this.data, 'title', 'aa');
    //-   this.$set(this.data, 'content', 'bb');
    //- }, 1500)
  }
}
</script>

<style lang="less" scoped></style>